import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SchedulerActions from './scheduler.actions';
import * as AppActions from '../app/actions';
import kpiCapacityUtilizationSchedulerDumpDataJson from '../../../assets/scheduler_dump_data/kpiCapacityUtilization.schedulerDumpData.json';
import kpiScheduledQualitySchedulerDumpDataJson from '../../../assets/scheduler_dump_data/kpiScheduledQuality.schedulerDumpData.json';
import kpiWorkOrderSchedulerDumpDataJson from '../../../assets/scheduler_dump_data/kpiWorkOrder.schedulerDumpData.json';
import kpiErrorSchedulerDumpDataJson from '../../../assets/scheduler_dump_data/kpiError.schedulerDumpData.json';
import schedulerGanttSchedulerDumpDataJson from '../../../assets/scheduler_dump_data/schedulerGantt.schedulerDumpData.json';
import { SchedulerService } from '../../shared/service/scheduler/scheduler.service';
import { WorkOrderScheduleService } from '../../shared/service/work-order-schedule/work-order-schedule.service';
import { FormattedWorkOrdersResponseInterface } from '../work-order-schedule/work-order-schedule.model';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { ActivitiesInterface } from '../../shared/model/interface/activities.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { LineAvailabilityPlanDataInterface } from '../line-availability/line-availability.model';
import {
  ESchedulerOptimizationStatus,
  ICheckForDeployItemsResponse,
  IScenarioGanttAllItemsResponse,
  ISchedulerKpiMetricInfo,
  ISchedulerOptimizationResponse,
  ScenarioItemInterface,
  ScenarioResponseInterface,
  ILinePathDetailsBySiteId,
} from './scheduler.model';
import { ScenarioBulkResponseDataInterface } from '../scheduler-scenario/scheduler-scenario.model';
import { ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import { emptyAction } from '../../../constants';
import { SchedulerWorkOrderSetService } from '../../shared/service/scheduler-work-order-set/scheduler-work-order-set.service';
import { IWorkOrderScheduleWithRules } from '../scheduler-work-order-set/scheduler-work-order-set.model';

@Injectable()
export class SchedulerEffects {
  constructor(
    private readonly actions$: Actions<SchedulerActions.SchedulerActions>,
    private readonly schedulerService: SchedulerService,
    private readonly workOrderScheduleService: WorkOrderScheduleService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly entityTranslator: EntityTranslatorService,
    private readonly schedulerWorkOrderSetService: SchedulerWorkOrderSetService,
  ) {}

  $loadScenarios = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadScenario),
      switchMap((objectData: SchedulerActions.LoadScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.schedulerService.loadScenario(objectData.scenarioId).pipe(
          switchMap((response: ScenarioResponseInterface) => {
            return of(
              ...[
                new SchedulerActions.ScenarioLoaded(response),
                new AppActions.HideLoader(),
                ...(objectData.isKpiMetricInfoLoad
                  ? [new SchedulerActions.KpiMetricInfoLoad(objectData.scenarioId)]
                  : []),
              ],
            );
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadScenarioItems = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadScenarioItems),
      switchMap((objectData: SchedulerActions.LoadScenarioItems) => {
        return this.schedulerService
          .loadScenarioGanttAllItems(objectData.scenario, objectData.startDate, objectData.endDate, objectData.lineIds)
          .pipe(
            switchMap((response: IScenarioGanttAllItemsResponse) => {
              response.scenarioItems.data.forEach((scenarioItem: ScenarioItemInterface) => {
                this.entityTranslator.translate(scenarioItem);
              });

              return of(new SchedulerActions.ScenarioItemsLoaded(response), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $saveScenarioItems = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.SaveScenarioItems),
      switchMap((objectData: SchedulerActions.SaveScenarioItems) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.schedulerService.saveScenarioItems(objectData.scenarioId, objectData.scenarioItems).pipe(
          switchMap((response) => {
            return of(
              new SchedulerActions.ScenarioItemsSaved(
                response,
                {
                  scenarioId: objectData.scenarioId,
                  scenarioItems: objectData.scenarioItems,
                  triggeredByKPI: objectData.triggeredByKPI,
                },
                objectData.linesToDeploy,
              ),
              new SchedulerActions.ShouldShowRefreshButton(),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $saveSchedulerKpiCardData = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.SaveSchedulerKpiCardData),
      exhaustMap((objectData: SchedulerActions.SaveSchedulerKpiCardData) => {
        return this.schedulerService
          .saveSchedulerKpiCardData(objectData.payload.scenarioId, objectData.payload.data)
          .pipe(
            switchMap((response: ScenarioResponseInterface) => {
              return of(new SchedulerActions.SchedulerKpiCardDataSaved(response));
            }),
            catchError((error) => {
              return of(new SchedulerActions.FetchError(error));
            }),
          );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error));
      }),
    ),
  );

  $updateScenarioDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.UpdateScenarioDetails),
      switchMap(() => {
        return of(new SchedulerActions.ScenarioDetailsUpdated());
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $deployScenarioItems = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.DeployScenarioItems),
      switchMap((objectData: SchedulerActions.DeployScenarioItems) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.schedulerService
          .deploy(objectData.horizon, objectData.deployedItems, objectData.siteId, objectData.uncreatedWorkOrders)
          .pipe(
            switchMap((response: BulkResponseDataInterface) => {
              return of(
                new SchedulerActions.ScenarioItemsDeployed(response.data, response.success),
                new SchedulerActions.ShouldShowRefreshButton(),
                new AppActions.HideTopLoader(),
              );
            }),
            catchError((error) => {
              return of(new SchedulerActions.FetchError(error), new AppActions.HideTopLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  fetchCapacityUtilizationData = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadCapacityUtilizationData),
      switchMap(() => {
        return of(new SchedulerActions.CapacityUtilizationDataLoaded(kpiCapacityUtilizationSchedulerDumpDataJson.data));
      }),
    ),
  );

  fetchScheduledQualityData = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadScheduledQualityData),
      switchMap(() => {
        return of(new SchedulerActions.ScheduledQualityDataLoaded(kpiScheduledQualitySchedulerDumpDataJson.data));
      }),
    ),
  );

  fetchWorkOrderData = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadWorkOrderData),
      switchMap(() => {
        return of(new SchedulerActions.WorkOrderDataLoaded(kpiWorkOrderSchedulerDumpDataJson.data));
      }),
    ),
  );

  fetchErrorData = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadErrorData),
      switchMap(() => {
        return of(new SchedulerActions.ErrorDataLoaded(kpiErrorSchedulerDumpDataJson.data));
      }),
    ),
  );

  loadScenarioGanttData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadScenarioGanttData),
      switchMap(() => {
        return of(
          new SchedulerActions.ScenarioGanttDataLoaded(
            schedulerGanttSchedulerDumpDataJson.columns,
            schedulerGanttSchedulerDumpDataJson.resources,
            schedulerGanttSchedulerDumpDataJson.events,
            new Date(schedulerGanttSchedulerDumpDataJson.startDate),
            new Date(schedulerGanttSchedulerDumpDataJson.endDate),
            schedulerGanttSchedulerDumpDataJson.viewPreset,
            schedulerGanttSchedulerDumpDataJson.groupFeature,
          ),
        );
      }),
    ),
  );

  $loadDownTimeActivitiesData = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadDownTimeActivitiesData),
      switchMap((objectData: SchedulerActions.LoadDownTimeActivitiesData) => {
        return this.schedulerService.getDownTimeActivities(objectData.activityIds).pipe(
          switchMap((response: GetManyResponseInterface<ActivitiesInterface>) => {
            response.data.forEach((activities: ActivitiesInterface) => {
              this.entityTranslator.translate(activities);
            });
            return of(new SchedulerActions.DownTimeActivitiesDataLoaded(response.data));
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadPlansData = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadScenarioShiftPlans),
      switchMap((objectData: SchedulerActions.LoadScenarioShiftPlans) => {
        return this.schedulerService.loadPlans(objectData.planIds).pipe(
          switchMap((response: GetManyResponseInterface<LineAvailabilityPlanDataInterface>) => {
            return of(new SchedulerActions.ScenarioShiftPlansLoaded(response.data));
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $saveManyScenarioWorkOrderRules = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.SaveManyScenarioWorkOrderRules),
      switchMap((objectData: SchedulerActions.SaveManyScenarioWorkOrderRules) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.schedulerService.saveManyScenarioWorkOrderRules(objectData.payload).pipe(
          switchMap((response: ScenarioBulkResponseDataInterface) => {
            return of(new AppActions.HideLoader(), new SchedulerActions.ScenarioWorkOrderRulesAdded(response));
          }),
          catchError((error) => {
            return of(new AppActions.HideLoader(), new SchedulerActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $setAsDefault = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.AddScenarioAdvancedFilter),
      switchMap((objectData: SchedulerActions.AddScenarioAdvancedFilter) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.schedulerService.setAsDefault(objectData.advancedFilter).pipe(
          switchMap((response: ScenarioResponseInterface) => {
            return of(new AppActions.HideLoader(), new SchedulerActions.ScenarioAdvancedFilterAdded(response));
          }),
          catchError((error) => {
            return of(new AppActions.HideLoader(), new SchedulerActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $checkWorkOrderData = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.CheckAddWhiteWorkOrder),
      switchMap((objectData: SchedulerActions.CheckAddWhiteWorkOrder) => {
        return this.workOrderScheduleService.checkWorkOrders(objectData.woNumber, objectData.siteId).pipe(
          switchMap((response: FormattedWorkOrdersResponseInterface) => {
            return of(new SchedulerActions.AddWhiteWorkOrderChecked(response));
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadUnplannedWorkOrderData = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadUnplannedWorkOrderData),
      switchMap((objectData: SchedulerActions.LoadUnplannedWorkOrderData) => {
        return this.schedulerWorkOrderSetService.getWorkOrdersForSchedulerRules(objectData.filters).pipe(
          switchMap((response: GetManyResponseInterface<IWorkOrderScheduleWithRules>) => {
            response.data.forEach((workOrder: IWorkOrderScheduleWithRules) =>
              this.entityTranslator.translate(workOrder),
            );
            const formattedUnPlannedWorkOrders: IWorkOrderScheduleWithRules[] =
              this.schedulerWorkOrderSetService.formatUnPlannedWorkOrders(response.data);

            return of(new SchedulerActions.UnplannedWorkOrderDataLoaded(formattedUnPlannedWorkOrders));
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $optimizeScheduler = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.OptimizeScheduler),
      mergeMap((objectData: SchedulerActions.OptimizeScheduler) => {
        return this.schedulerService.optimizeScheduler(objectData.scenarioId, objectData.optimizationType).pipe(
          mergeMap((response: boolean) => {
            return of(
              new SchedulerActions.SchedulerOptimized(response),
              new SchedulerActions.LoadSchedulerOptimizations(objectData.scenarioId),
            );
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadSchedulerOptimizations = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadSchedulerOptimizations),
      switchMap((objectData: SchedulerActions.LoadSchedulerOptimizations) => {
        return this.schedulerService.getSchedulerOptimizations(objectData.scenarioId).pipe(
          switchMap((response: BaseOneResponseInterface<ISchedulerOptimizationResponse[]>) => {
            const completedOptimizations: ISchedulerOptimizationResponse[] = response.data?.filter(
              (optimization: ISchedulerOptimizationResponse) =>
                optimization.status === ESchedulerOptimizationStatus.COMPLETED,
            );

            return of(
              new SchedulerActions.SchedulerOptimizationsLoaded({
                completedOptimizations,
                allOptimizations: response.data,
              }),
            );
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $updateOptimizationScheduler = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.UpdateSchedulerOptimization),
      mergeMap((objectData: SchedulerActions.UpdateSchedulerOptimization) => {
        return this.schedulerService.updateSchedulerOptimization(objectData.scenarioId, objectData.params).pipe(
          mergeMap((response: ResponseInterface<boolean>) => {
            return of(new SchedulerActions.SchedulerOptimizationUpdated(response.data));
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $getSchedulerKpiMetricInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.KpiMetricInfoLoad),
      switchMap((objectData: SchedulerActions.KpiMetricInfoLoad) => {
        return this.schedulerService.getSchedulerKpiMetricInfo(objectData.scenarioId).pipe(
          switchMap((response: BaseOneResponseInterface<ISchedulerKpiMetricInfo>) => {
            return of(new SchedulerActions.KpiMetricInfoLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $saveSchedulerKpiCardConfigs = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.SaveSchedulerKpiCardConfigs),
      switchMap((objectData: SchedulerActions.SaveSchedulerKpiCardConfigs) => {
        return this.schedulerService
          .saveSchedulerKpiCardConfigs(objectData.payload.scenarioId, objectData.payload.config)
          .pipe(
            switchMap(() => {
              return emptyAction;
            }),
            catchError((error) => {
              return of(new SchedulerActions.FetchError(error));
            }),
          );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error));
      }),
    ),
  );

  $checkForDeployItems = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.CheckForDeployItems),
      switchMap((objectData: SchedulerActions.CheckForDeployItems) => {
        return this.schedulerService.checkForDeployItems(objectData.checkForDeployItems).pipe(
          switchMap((response: GetManyResponseInterface<ICheckForDeployItemsResponse>) => {
            return of(new SchedulerActions.CheckForDeployItemsCompleted(response));
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $saveScenarioUncreatedWorkOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.SaveScenarioUncreatedWorkOrders),
      switchMap((objectData: SchedulerActions.SaveScenarioUncreatedWorkOrders) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.schedulerService
          .saveUncreatedWorkOrders(objectData.scenarioId, objectData.uncreatedWorkOrders)
          .pipe(
            switchMap((response: ScenarioBulkResponseDataInterface) => {
              return of(
                new SchedulerActions.ScenarioUncreatedWorkOrdersSaved(response),
                new AppActions.HideTopLoader(),
              );
            }),
            catchError((error) => {
              return of(new SchedulerActions.FetchError(error), new AppActions.HideTopLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $deleteScenarioUncreatedWorkOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.DeleteScenarioUncreatedWorkOrders),
      switchMap((objectData: SchedulerActions.DeleteScenarioUncreatedWorkOrders) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.schedulerService.deleteUncreatedWorkOrders(objectData.uncreatedWorkOrderIds).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(
              new SchedulerActions.ScenarioUncreatedWorkOrdersDeleted(response),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );

  $getLinePathData = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.LoadLinePathData),
      switchMap((objectData: SchedulerActions.LoadLinePathData) => {
        return this.schedulerService.getLinePathData(objectData.siteId).pipe(
          switchMap((response: GetManyResponseInterface<ILinePathDetailsBySiteId>) => {
            return of(new SchedulerActions.LinePathDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $downloadSchedulerListViewExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulerActions.SchedulerActionTypes.SchedulerDownloadExcel),
      switchMap((objectData: SchedulerActions.SchedulerListViewDownloadExcel) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        this.schedulerService.downloadListViewExcel(objectData.payload);

        return emptyAction;
      }),
      catchError((error) => {
        return of(new SchedulerActions.FetchError(error), new AppActions.HideTopLoader());
      }),
    ),
  );
}
