import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ICardCarouselConfiguration, IScrollInformation, ScrollBehaviour } from './card-carousel.model';
import * as _ from 'lodash';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-card-carousel',
  templateUrl: './card-carousel.component.html',
  styleUrls: ['./card-carousel.component.scss'],
})
export class CardCarouselComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('carousel') cardCarousel: ElementRef;

  @Input() cardCarouselConfiguration: ICardCarouselConfiguration;

  @Output() autoScrollActive: boolean = false;

  private readonly defaultScrollPeriod: number = 5000;

  private cardScrollIntervals: Timeout[] = [];
  private scrollBehaviour: ScrollBehaviour;

  public ngAfterViewInit(): void {
    this.scrollBehaviour = this.scrollBehaviourFactory(this.cardCarouselConfiguration);
    this.startAutoScroll(this.cardCarouselConfiguration.scrollPeriod);

    if (this.cardCarousel.nativeElement.children.length > 0) {
      this.cardCarousel.nativeElement.addEventListener('mouseenter', (): void => {
        this.removeAutoScroll();
      });

      this.cardCarousel.nativeElement.addEventListener('mouseleave', (): void => {
        this.startAutoScroll(this.cardCarouselConfiguration.scrollPeriod);
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('cardCarouselConfiguration' in changes && !_.isEqual(changes['cardCarouselConfiguration'].currentValue, changes['cardCarouselConfiguration'].previousValue)) {
      this.scrollBehaviour = this.scrollBehaviourFactory(changes['cardCarouselConfiguration'].currentValue);
    }
  }

  public ngOnDestroy(): void {
    if (this.cardScrollIntervals) {
      this.removeAutoScroll();
    }
  }

  public startAutoScroll(period: number = this.defaultScrollPeriod): void {
    if (_.isNil(this.cardCarousel.nativeElement.children)) {
      return;
    }

    if (this.cardScrollIntervals.length) {
      this.removeAutoScroll();
    }

    const cardScrollIntervalId: Timeout = setInterval((): void => {
      const scrollInformation: IScrollInformation = this.getScrollInformationFromConfiguration();
      if (this.scrollBehaviour.isScrollable(scrollInformation)) {
        this.cardCarousel.nativeElement.scrollTo(this.scrollBehaviour.getScrollToOptions(scrollInformation));
      } else {
        this.cardCarousel.nativeElement.scrollTo(this.scrollBehaviour.getScrollEndOptions(scrollInformation));
      }
    }, period);

    this.cardScrollIntervals.push(cardScrollIntervalId);
  }

  private getScrollInformationFromConfiguration(): IScrollInformation {
    let scrollLength: number = 0;
    let scrollOffset: number = 0;
    let scrollStart: number = 0;
    let cardScrollAlignedLength: number = 0;

    switch (this.cardCarouselConfiguration.scrollDirection) {
      case 'upToDown':
        scrollLength = this.cardCarousel.nativeElement.scrollHeight;
        scrollOffset = this.cardCarousel.nativeElement.offsetHeight;
        scrollStart = this.cardCarousel.nativeElement.scrollTop;
        cardScrollAlignedLength = _.first(this.cardCarousel.nativeElement.children).clientHeight;
        break;
      case 'downToUp':
      case 'leftToRight':
      case 'rightToLeft':
      default:
        break;
    }

    return {
      scrollLength,
      scrollOffset,
      scrollStart,
      cardScrollAlignedLength,
    };
  }

  private scrollBehaviourFactory(cardCarouselConfiguration: ICardCarouselConfiguration): ScrollBehaviour {
    let scrollBehaviour;
    switch (cardCarouselConfiguration.scrollDirection) {
      case 'upToDown':
        scrollBehaviour = {
          isScrollable: (scrollInformation: IScrollInformation): boolean =>
            scrollInformation.scrollStart + scrollInformation.scrollOffset <
            scrollInformation.scrollLength - scrollInformation.cardScrollAlignedLength / 2,
          getScrollToOptions: (scrollInformation: IScrollInformation): ScrollToOptions => ({
            top: scrollInformation.scrollStart + scrollInformation.cardScrollAlignedLength,
            behavior: cardCarouselConfiguration.scrollMovementBehavior,
          }),
          getScrollEndOptions: (): ScrollToOptions => ({
            top: 0,
            behavior: cardCarouselConfiguration.scrollMovementBehavior,
          }),
        };
        break;
      case 'downToUp':
      case 'rightToLeft':
      case 'leftToRight':
      default:
        break;
    }

    return scrollBehaviour;
  }

  public removeAutoScroll(): void {
    this.cardScrollIntervals.forEach((cardScrollInterval: Timeout): void => {
      clearInterval(cardScrollInterval);
    });
    this.cardScrollIntervals.length = 0;
  }

  public scrollToElementWithId(targetId: string): void {
    this.removeAutoScroll();
    document.querySelector(targetId).scrollIntoView({
      behavior: this.cardCarouselConfiguration.scrollMovementBehavior,
      block: 'start',
      inline: 'nearest',
    });
    this.startAutoScroll(this.cardCarouselConfiguration.scrollPeriod);
  }
}
