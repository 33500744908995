import { Action } from '@ngrx/store';
import {
  ChangeActivityRequestInterface,
  HomeActivityButtonInterface,
  HomeInformationInterface,
  UpdateCurrentActivityInterface,
  CheckWorkOrderRequestInterface,
  UpdateWorkOrderRequestInterface,
  GetWorkOrderRequestInterface,
  WorkOrderStatusRequestInterface,
  GetVersionDetailRequestInterface,
  GetProductPerformanceRequestInterface,
  GetWorkOrderNumbersRequestInterface,
  TreeChartServiceInterface,
  UpdateCurrentActivityResponseInterface,
  PhaseCommentStoreDataInterface,
  PhaseCommentStoreUpdateDataInterface,
  ProductSearchDispatchAreas,
  PhaseCommentsEnteredStatusInterface,
  ILineInfoThatWorkOrder,
  RequestTypeEnum,
  IRemoveWorkOrderRequestBody,
  IGetLineInfoThatWorkOrderAdditionalParams,
} from './home.model';
import { ChangeActivityResponseInterface } from '../line/model';
import { ShiftSummaryCommentObjectPropertyTypes } from '../../shared/model/enum/shift-summary-comment-object-property-types';
import {
  WorkOrderCloseFeedbackInterface,
  WorkOrderCloseFeedbackResponseInterface,
} from '../work-order/work-order.model';
import { ProductCRUDResponseInterface } from '../../shared/service/filter/service.class';
import { PhaseOptionNames } from '../../shared/model/enum/phase-options';
import { EquipmentListGetOneCRUDDataInterface } from '../../shared/service/equipment-list/equipment-list.model';
import { UserGetOneCRUDDataInterface } from '../../shared/service/user/user.model';
import { WorkOrderScheduleInterface } from '../work-order-schedule/work-order-schedule.model';
import { IProductVersion } from '../settings/products/products.model';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { IJob } from '../settings/jobs/jobs.model';
import { BulkResponseDataInterface } from '../../shared/model/interface/crud-response-interface.model';
import {
  PhaseDurationsInterface,
  PhaseDurationTypeEnum,
} from '../../view/home/home-metrics/common-phase-duration-metric/common-phase-duration-metric.model';
import { ILayout } from '../settings/home-page-display-settings/home-page-display-settings.model';
import { EPages } from '../../view/settings/home-page-display-settings/home-page-display-settings.constants';
import { ResponseArrayInterface } from '../../shared/model/interface/generic-api-response.model';
import { IActivityHistory } from '../../shared/service/activity-history/activity-history.model';
import { DatatableInterface } from '../../shared/service/datatable/datatable.model';
import {
  IGetProductTransitionMatrix,
  IGetProductTransitionMatrixParams,
} from '../settings/product-transition-matrix/product-transition-matrix.model';
import { LinePathAssignmentMethods } from '../../shared/component/select-work-order/select-work-order.model';
import {
  IWorkOrderControlParams,
  IWorkOrderControlResponse,
} from '../../shared/service/work-order-schedule/work-order-schedule.model';
import { IPredictedWorkOrderInfo } from '../../view/home/work-order/work-order.model';

export enum HomeActionTypes {
  StartLoadHomeInformation = '[Home] Start Load Home Information',
  HomeInformationLoaded = '[Home] Home Information Loaded',
  SetHomeActivityButtons = '[Home] Set Home Activity Buttons',
  GetQuantityFromSensor = '[Home] Get Quantity From Sensor',
  GetActivityTreeGraph = '[Home] Get Activity Tree Graph',
  ActivityTreeGraphDataLoaded = '[Home] Activity Tree Graph Loaded',
  SaveWorkOrderFeedback = '[Home] Save Work Order Feedback',
  SaveWorkOrderFeedbackLoaded = '[Home] Save Work Order Feedback Loaded',
  ClearWorkOrderFeedbackForm = '[Home] Clear Work Order Feedback Form',
  GetWorkOrderFeedbackLoading = '[Home] Get Work Order Feedback Loading',
  GetWorkOrderFeedbackLoaded = '[Home] Get Work Order Feedback Loaded',
  UpdateWorkOrderFeedbackLoading = '[Home] Update Work Order Feedback Loading',
  UpdateWorkOrderFeedbackCompleted = '[Home] Update Work Order Feedback Completed',
  ChangeActivity = '[Home] Change Activity',
  ChangeActivityError = '[Home] Change Activity Error',
  SetChangeActivityLoading = '[Home] Set Change Activity Loading',
  ChangeActivityCompleted = '[Home] Change Activity Completed',
  SearchTaskByActivityId = '[Home] Search Task By Activity Id',
  SearchTaskByActivityIdCompleted = '[Home] Search Task By Activity Id Completed',
  GetTasksByActivityId = '[Home] Get Tasks By Activity Id',
  GetTasksByActivityIdCompleted = '[Home] Get Tasks By Activity Id Completed',
  UpdateCurrentActivity = '[Home] Update Current Activity',
  UpdateCurrentActivityCompleted = '[Home] Update Current Activity Completed',
  CheckWorkOrder = '[Home] Check Work Order',
  CheckWorkOrderCompleted = '[Home] Check Work Order Completed',
  UpdateWorkOrder = '[Home] Update Work Order',
  UpdateWorkOrderCompleted = '[Home] Update Work Order Completed',
  GetWorkOrder = '[Home] Get Work Order',
  GetWorkOrderCompleted = '[Home] Get Work Order Completed',
  WorkOrderStatus = '[Home] Work Order Status',
  WorkOrderStatusCompleted = '[Home] Work Order Status Completed',
  GetVersionDetail = '[Home] Get Version Detail',
  GetVersionDetailCompleted = '[Home] Get Version Detail Completed',
  GetProductPerformance = '[Home] Get Product Performance',
  GetProductPerformanceCompleted = '[Home] Get Product Performance Completed',
  ProductSearch = '[Home] Product Search',
  ProductSearchCompleted = '[Home] Product Search Completed',
  ProductSearchFinalized = '[Home] Product Search Finalized',
  GetWorkOrderNumbers = '[Home] Get Work Order Numbers',
  GetWorkOrderNumbersCompleted = '[Home] Get Work Order Numbers Completed',
  FetchError = '[Home] Fetch Error',
  SearchUsersByFilter = '[Home] Search Users By Filter',
  SearchAssetsByFilter = '[Home] Search Assets By Filter',
  SearchUsersByFilterCompleted = '[Home] Search Users By Filter Completed',
  SearchAssetsByFilterCompleted = '[Home] Search Assets By Filter Completed',
  HomeMetricSetItemsSetDefaultState = '[Home] Home metric set set default state',
  HomeMetricSetItemsLoading = '[Home] Home metric set items loading',
  HomeMetricSetItemsLoaded = '[Home] Home metric set items loaded',
  PhaseDurationsLoading = '[Home] Phase durations loading',
  PhaseDurationsLoaded = '[Home] Phase durations loaded',
  PhaseDurationIntervalTick = '[Home] Phase duration interval tick',
  ResetPhaseDurationOnSiteLineSelection = '[Home] Reset phase duration on site line selection',
  PhaseCommentsLoading = '[Home] Phase comments loading',
  PhaseCommentsLoaded = '[Home] Phase comments loaded',
  UpdatePhaseComment = '[Home] Update phase comment',
  UpdatePhaseCommentCompleted = '[Home] Update phase comment completed',
  DeletePhaseComment = '[Home] Delete phase comment',
  DeletePhaseCommentCompleted = '[Home] Delete phase comment completed',
  CreatePhaseComment = '[Home] Create phase comment',
  CreatePhaseCommentCompleted = '[Home] Create phase comment completed',
  UpdateIsAutomatedPhaseCommentsActive = '[Home] Update is automated phase comments active',
  UpdatePhaseCommentsEnteredStatus = '[Home] Update phase comments entered status',
  UpdatePreviousPhase = '[Home] Update previous phase',
  GetLineInfoThatSelectedWorkOrder = '[Home] Get Line Info That Selected Work Order',
  GetLineInfoThatSelectedWorkOrderCompleted = '[Home] Get Line Info That Selected WorkOrder Completed',
  SetRequestType = '[Home] Set Request Type',
  SplitWorkOrderOnLineLoad = '[Home] Split Work Order On Line Load',
  SplitWorkOrderOnLinePredictedWorkOrderNumberLoad = '[Home] Split Work Order On Line Predicted Work Order Number Load',
  SplitWorkOrderOnLinePredictedWorkOrderNumberLoaded = '[Home] Split Work Order On Line Predicted Work Order Number Loaded',
  ProductVersionsLoad = '[Home] Product Versions Load',
  ProductVersionsLoaded = '[Home] Product Versions Loaded',
  GetJobs = '[Home] Get jobs',
  GetJobsCompleted = '[Home] Get jobs completed',
  CreateJob = '[Home] Create Job',
  CreateJobCompleted = '[Home] Create Job Completed',
  UpdateLineInformation = '[Home] Update Line Information',
  UpdateLineInformationCompleted = '[Home] Update Line Information Completed',
  ShowTaskTargetExceedErrorMessage = '[Home] Show Task Target Exceed Error Message',
  HideTaskTargetExceedErrorMessage = '[Home] Hide Task Target Exceed Error Message',
  IsOpcIntegrationInterrupted = '[Home] Is Opc Integration Interrupted',
  StartActivityHistoryDataLoading = '[Home] Start Activity History Data Loading',
  ActivityHistoryDataLoaded = '[Home] Activity History Data Loaded',
  GetProductTransitionMatrix = '[Home] Get Product Transition Matrix',
  GetProductTransitionMatrixCompleted = '[Home] Get Product Transition Matrix Completed',
  GetPreviousWorkOrder = '[Home] Get Previous Work Order',
  GetPreviousWorkOrderCompleted = '[Home] Get Previous Work Order Completed',
  GetIsLinePathUtilized = '[Home] Get Is Line Path Utilized',
  GetIsLinePathUtilizedCompleted = '[Home] Get Is Line Path Utilized Completed',
  AssignWoLinePathJob = '[Home] Assign WO Line Path Job',
  AssignWoLinePathJobCompleted = '[Home] Assign WO Line Path Job Completed',
  MultipleChangeActivity = '[Home] Multiple Change Activity',
  MultipleChangeActivityDone = '[Home] Multiple Change Activity Done',
  WorkOrderMissingControl = '[Home] Work Order Missing Control',
  WorkOrderMissingControlCompleted = '[Home] Work Order Missing Control Completed',
}

export class StartLoadHomeInformation implements Action {
  readonly type = HomeActionTypes.StartLoadHomeInformation;

  constructor(public page: string = 'home', public lineID?: number) {}
}

export class FetchError implements Action {
  readonly type = HomeActionTypes.FetchError;

  constructor(public payload: any[], public actionType?: HomeActionTypes) {}
}

export class HomeInformationLoaded implements Action {
  readonly type = HomeActionTypes.HomeInformationLoaded;

  constructor(public payload: HomeInformationInterface, public page: string = 'home', public lineID?: number) {}
}

export class SetHomeActivityButtons implements Action {
  readonly type = HomeActionTypes.SetHomeActivityButtons;

  constructor(public payload: HomeActivityButtonInterface[]) {}
}

export class GetQuantityFromSensor implements Action {
  readonly type = HomeActionTypes.GetQuantityFromSensor;

  constructor(public hideLoaderAfterwards: boolean = false, public lineID?: number) {}
}

export class GetActivityTreeGraph implements Action {
  readonly type = HomeActionTypes.GetActivityTreeGraph;

  constructor(public lineId: number, public activityType?: string) {}
}

export class ActivityTreeGraphDataLoaded implements Action {
  readonly type = HomeActionTypes.ActivityTreeGraphDataLoaded;

  constructor(public payload: TreeChartServiceInterface) {}
}

export class SaveWorkOrderFeedback implements Action {
  readonly type = HomeActionTypes.SaveWorkOrderFeedback;

  constructor(public workOrderCloseFeedbackData: WorkOrderCloseFeedbackInterface) {}
}

export class SaveWorkOrderFeedbackLoaded implements Action {
  readonly type = HomeActionTypes.SaveWorkOrderFeedbackLoaded;

  constructor(public payload: WorkOrderCloseFeedbackResponseInterface) {}
}

export class ClearWorkOrderFeedbackForm implements Action {
  readonly type = HomeActionTypes.ClearWorkOrderFeedbackForm;
}

export class GetWorkOrderFeedback implements Action {
  readonly type = HomeActionTypes.GetWorkOrderFeedbackLoading;

  constructor(public workOrderScheduleTableId: number) {}
}

export class GetWorkOrderFeedbackLoaded implements Action {
  readonly type = HomeActionTypes.GetWorkOrderFeedbackLoaded;

  constructor(public workOrderCloseFeedbackData: WorkOrderCloseFeedbackInterface) {}
}

export class UpdateWorkOrderFeedback implements Action {
  readonly type = HomeActionTypes.UpdateWorkOrderFeedbackLoading;

  constructor(public workOrderCloseFeedbackData: WorkOrderCloseFeedbackInterface) {}
}

export class UpdateWorkOrderFeedbackCompleted implements Action {
  readonly type = HomeActionTypes.UpdateWorkOrderFeedbackCompleted;

  constructor(public payload: WorkOrderCloseFeedbackResponseInterface) {}
}

export class ChangeActivity implements Action {
  readonly type = HomeActionTypes.ChangeActivity;

  constructor(public requestData: ChangeActivityRequestInterface, public overwriteSiteAndLineIds: boolean = true) {}
}

export class MultipleChangeActivity implements Action {
  readonly type = HomeActionTypes.MultipleChangeActivity;

  constructor(public requestData: ChangeActivityRequestInterface[]) {}
}

export class MultipleChangeActivityDone implements Action {
  readonly type = HomeActionTypes.MultipleChangeActivityDone;
}

export class ChangeActivityError implements Action {
  readonly type = HomeActionTypes.ChangeActivityError;
}

export class SetChangeActivityLoading implements Action {
  readonly type = HomeActionTypes.SetChangeActivityLoading;

  constructor(public loadingState: boolean) {}
}

export class ChangeActivityCompleted implements Action {
  readonly type = HomeActionTypes.ChangeActivityCompleted;

  constructor(public payload: ChangeActivityResponseInterface, public finalizeWorkOrder: boolean) {}
}

export class GetTasksByActivityId implements Action {
  readonly type = HomeActionTypes.GetTasksByActivityId;

  constructor(public activityId: number, public action: string) {}
}

export class GetTasksByActivityIdCompleted implements Action {
  readonly type = HomeActionTypes.GetTasksByActivityIdCompleted;

  constructor(public payload: any, public action: string) {}
}

export class SearchTaskByActivityId implements Action {
  readonly type = HomeActionTypes.SearchTaskByActivityId;

  constructor(
    public activityId: number,
    public equipmentSearch?: boolean,
    public search?: string,
    public siteId?: string,
    public lineId?: string,
  ) {}
}

export class SearchTaskByActivityIdCompleted implements Action {
  readonly type = HomeActionTypes.SearchTaskByActivityIdCompleted;

  constructor(public payload: any) {}
}

export class UpdateCurrentActivity implements Action {
  readonly type = HomeActionTypes.UpdateCurrentActivity;

  constructor(
    public payload: UpdateCurrentActivityInterface,
    public requestType: RequestTypeEnum = RequestTypeEnum.DEFAULT,
    public page: EPages = EPages.HOME,
  ) {}
}

export class UpdateCurrentActivityCompleted implements Action {
  readonly type = HomeActionTypes.UpdateCurrentActivityCompleted;
  constructor(public payload: UpdateCurrentActivityResponseInterface) {}
}

export class CheckWorkOrder implements Action {
  readonly type = HomeActionTypes.CheckWorkOrder;

  constructor(public payload: CheckWorkOrderRequestInterface) {}
}

export class CheckWorkOrderCompleted implements Action {
  readonly type = HomeActionTypes.CheckWorkOrderCompleted;

  constructor(public payload: WorkOrderScheduleInterface[]) {}
}

export class UpdateWorkOrder implements Action {
  readonly type = HomeActionTypes.UpdateWorkOrder;

  constructor(
    public payload: UpdateWorkOrderRequestInterface,
    public workOrderId: number,
    public isSiteLineIncluded: boolean = true,
  ) {}
}

export class UpdateWorkOrderCompleted implements Action {
  readonly type = HomeActionTypes.UpdateWorkOrderCompleted;

  constructor(public payload: any) {}
}

export class GetWorkOrder implements Action {
  readonly type = HomeActionTypes.GetWorkOrder;

  constructor(public payload: GetWorkOrderRequestInterface) {}
}

export class GetWorkOrderCompleted implements Action {
  readonly type = HomeActionTypes.GetWorkOrderCompleted;

  constructor(public payload: any) {}
}

export class WorkOrderStatus implements Action {
  readonly type = HomeActionTypes.WorkOrderStatus;

  constructor(public payload: WorkOrderStatusRequestInterface) {}
}

export class WorkOrderStatusCompleted implements Action {
  readonly type = HomeActionTypes.WorkOrderStatusCompleted;

  constructor(public payload: WorkOrderScheduleInterface) {}
}

export class GetVersionDetail implements Action {
  readonly type = HomeActionTypes.GetVersionDetail;

  constructor(public payload: GetVersionDetailRequestInterface) {}
}

export class GetVersionDetailCompleted implements Action {
  readonly type = HomeActionTypes.GetVersionDetailCompleted;

  constructor(public payload: any) {}
}

export class GetProductPerformance implements Action {
  readonly type = HomeActionTypes.GetProductPerformance;

  constructor(public payload: GetProductPerformanceRequestInterface) {}
}

export class GetProductPerformanceCompleted implements Action {
  readonly type = HomeActionTypes.GetProductPerformanceCompleted;

  constructor(public payload: any) {}
}

export class ProductSearch implements Action {
  readonly type = HomeActionTypes.ProductSearch;

  constructor(
    public dispatchedBy: ProductSearchDispatchAreas,
    public siteId: number,
    public filter?: string,
    public page?: number,
  ) {}
}

export class ProductSearchCompleted implements Action {
  readonly type = HomeActionTypes.ProductSearchCompleted;

  constructor(public payload: ProductCRUDResponseInterface) {}
}

export class ProductSearchFinalized implements Action {
  readonly type = HomeActionTypes.ProductSearchFinalized;
}

export class GetWorkOrderNumbers implements Action {
  readonly type = HomeActionTypes.GetWorkOrderNumbers;

  constructor(public payload: GetWorkOrderNumbersRequestInterface, public homeStationId?: number) {}
}

export class GetWorkOrderNumbersCompleted implements Action {
  readonly type = HomeActionTypes.GetWorkOrderNumbersCompleted;

  constructor(public data: WorkOrderScheduleInterface[], public dispatchedBy: string) {}
}

export class SearchUsersByFilter implements Action {
  readonly type = HomeActionTypes.SearchUsersByFilter;

  constructor(
    public filter: string,
    public lineId: number = null,
    public userId: number = null,
    public forCheckOutAll: boolean = false,
    public excludedIds: number[] = null,
    public excludeNonCheckables: boolean = false,
  ) {}
}

export class SearchUsersByFilterCompleted implements Action {
  readonly type = HomeActionTypes.SearchUsersByFilterCompleted;

  constructor(public data: UserGetOneCRUDDataInterface[], public isAuthorizedUserSearch: boolean = false) {}
}

export class SearchAssetsByFilter implements Action {
  readonly type = HomeActionTypes.SearchAssetsByFilter;

  constructor(public siteId: number, public equipmentId: number = null, public searchText?: string) {}
}

export class SearchAssetsByFilterCompleted implements Action {
  readonly type = HomeActionTypes.SearchAssetsByFilterCompleted;

  constructor(public data: EquipmentListGetOneCRUDDataInterface[]) {}
}

export class HomeMetricSetItemsSetDefaultState implements Action {
  readonly type = HomeActionTypes.HomeMetricSetItemsSetDefaultState;
}

export class HomeMetricSetItemsLoading implements Action {
  readonly type = HomeActionTypes.HomeMetricSetItemsLoading;

  constructor(public lineId: number, public page: string = EPages.HOME) {}
}

export class HomeMetricSetItemsLoaded implements Action {
  readonly type = HomeActionTypes.HomeMetricSetItemsLoaded;

  constructor(public payload: ILayout[]) {}
}

export class PhaseDurationsLoading implements Action {
  readonly type = HomeActionTypes.PhaseDurationsLoading;

  constructor(public lineId: number, public phase: PhaseOptionNames = null) {}
}

export class PhaseDurationsLoaded implements Action {
  readonly type = HomeActionTypes.PhaseDurationsLoaded;

  constructor(public payload: PhaseDurationsInterface, public phase: PhaseOptionNames) {}
}

export class PhaseDurationIntervalTick implements Action {
  readonly type = HomeActionTypes.PhaseDurationIntervalTick;

  constructor(public phaseTickType: PhaseDurationTypeEnum) {}
}

export class ResetPhaseDurationOnSiteLineSelection implements Action {
  readonly type = HomeActionTypes.ResetPhaseDurationOnSiteLineSelection;
}

export class PhaseCommentsLoading implements Action {
  readonly type = HomeActionTypes.PhaseCommentsLoading;

  constructor(public workOrderId: number) {}
}

export class PhaseCommentsLoaded implements Action {
  readonly type = HomeActionTypes.PhaseCommentsLoaded;

  constructor(public response: PhaseCommentStoreDataInterface) {}
}

export class UpdatePhaseComment implements Action {
  readonly type = HomeActionTypes.UpdatePhaseComment;

  constructor(public id: number, public message: null | string, public folderId?: number | null) {}
}

export class UpdatePhaseCommentCompleted implements Action {
  readonly type = HomeActionTypes.UpdatePhaseCommentCompleted;

  constructor(public response: PhaseCommentStoreUpdateDataInterface) {}
}

export class DeletePhaseComment implements Action {
  readonly type = HomeActionTypes.DeletePhaseComment;

  constructor(public id: number) {}
}

export class DeletePhaseCommentCompleted implements Action {
  readonly type = HomeActionTypes.DeletePhaseCommentCompleted;

  constructor(public deletedId: number) {}
}

export class CreatePhaseComment implements Action {
  readonly type = HomeActionTypes.CreatePhaseComment;

  constructor(
    public siteId: number,
    public workOrderId: number,
    public message: null | string,
    public phaseType: ShiftSummaryCommentObjectPropertyTypes,
    public folderId?: number,
  ) {}
}

export class CreatePhaseCommentCompleted implements Action {
  readonly type = HomeActionTypes.CreatePhaseCommentCompleted;

  constructor(public response: PhaseCommentStoreUpdateDataInterface) {}
}

export class UpdateIsAutomatedPhaseCommentsActive implements Action {
  readonly type = HomeActionTypes.UpdateIsAutomatedPhaseCommentsActive;

  constructor(public isAutomatedPhaseCommentsActive: boolean) {}
}

export class UpdatePhaseCommentsEnteredStatus implements Action {
  readonly type = HomeActionTypes.UpdatePhaseCommentsEnteredStatus;

  constructor(public phaseCommentsEnteredStatus: PhaseCommentsEnteredStatusInterface) {}
}

export class UpdatePreviousPhase implements Action {
  readonly type = HomeActionTypes.UpdatePreviousPhase;

  constructor(public previousPhase: number) {}
}

export class GetLineInfoThatWorkOrderLoading implements Action {
  readonly type = HomeActionTypes.GetLineInfoThatSelectedWorkOrder;

  constructor(
    public id: number,
    public lineId: number,
    public additionalParams: Partial<IGetLineInfoThatWorkOrderAdditionalParams> = {},
  ) {}
}

export class GetLineInfoThatWorkOrderLoaded implements Action {
  readonly type = HomeActionTypes.GetLineInfoThatSelectedWorkOrderCompleted;

  constructor(public payload: ILineInfoThatWorkOrder) {}
}

export class SetRequestType implements Action {
  readonly type = HomeActionTypes.SetRequestType;

  constructor(public requestType: RequestTypeEnum) {}
}

export class SplitWorkOrderOnLineLoad implements Action {
  readonly type = HomeActionTypes.SplitWorkOrderOnLineLoad;

  constructor(public id: number, public copyTargetSpeed: boolean) {}
}

export class SplitWorkOrderOnLinePredictedWorkOrderNumberLoad implements Action {
  readonly type = HomeActionTypes.SplitWorkOrderOnLinePredictedWorkOrderNumberLoad;

  constructor(public id: number) {}
}

export class SplitWorkOrderOnLinePredictedWorkOrderNumberLoaded implements Action {
  readonly type = HomeActionTypes.SplitWorkOrderOnLinePredictedWorkOrderNumberLoaded;

  constructor(public payload: IPredictedWorkOrderInfo) {}
}

export class ProductVersionsLoad implements Action {
  readonly type = HomeActionTypes.ProductVersionsLoad;

  constructor(public productId: number) {}
}

export class ProductVersionsLoaded implements Action {
  readonly type = HomeActionTypes.ProductVersionsLoaded;

  constructor(public payload: IProductVersion[]) {}
}

export class GetJobs implements Action {
  readonly type = HomeActionTypes.GetJobs;

  constructor(
    public readonly siteId: number,
    public readonly searchText: string = null,
    public readonly jobId: number = null,
  ) {}
}

export class GetJobsCompleted implements Action {
  readonly type = HomeActionTypes.GetJobsCompleted;
  constructor(public payload: GetManyResponseInterface<IJob>) {}
}

export class CreateJob implements Action {
  readonly type = HomeActionTypes.CreateJob;

  constructor(public readonly job: Partial<IJob>) {}
}

export class CreateJobCompleted implements Action {
  readonly type = HomeActionTypes.CreateJobCompleted;

  constructor(public readonly job: IJob) {}
}

export class UpdateLineInformation implements Action {
  readonly type = HomeActionTypes.UpdateLineInformation;

  constructor(public id: number, public body: IRemoveWorkOrderRequestBody) {}
}

export class UpdateLineInformationCompleted implements Action {
  readonly type = HomeActionTypes.UpdateLineInformationCompleted;

  constructor(public response: BulkResponseDataInterface) {}
}

export class ShowTaskTargetExceedErrorMessage implements Action {
  readonly type = HomeActionTypes.ShowTaskTargetExceedErrorMessage;
}

export class HideTaskTargetExceedErrorMessage implements Action {
  readonly type = HomeActionTypes.HideTaskTargetExceedErrorMessage;
}

export class IsOpcIntegrationInterrupted implements Action {
  readonly type = HomeActionTypes.IsOpcIntegrationInterrupted;

  constructor(public value: boolean | null = null) {}
}

export class StartActivityHistoryDataLoading implements Action {
  readonly type = HomeActionTypes.StartActivityHistoryDataLoading;

  constructor(public searchObject: object, public tableQuery?: DatatableInterface) {}
}

export class ActivityHistoryDataLoaded implements Action {
  readonly type = HomeActionTypes.ActivityHistoryDataLoaded;

  constructor(public payload: ResponseArrayInterface<IActivityHistory>) {}
}

export class GetProductTransitionMatrix implements Action {
  readonly type = HomeActionTypes.GetProductTransitionMatrix;

  constructor(public readonly payload: IGetProductTransitionMatrixParams) {}
}

export class GetProductTransitionMatrixCompleted implements Action {
  readonly type = HomeActionTypes.GetProductTransitionMatrixCompleted;

  constructor(public payload: IGetProductTransitionMatrix) {}
}

export class GetPreviousWorkOrder implements Action {
  readonly type = HomeActionTypes.GetPreviousWorkOrder;

  constructor(public readonly siteId: number, public readonly lineId: number) {}
}

export class GetPreviousWorkOrderCompleted implements Action {
  readonly type = HomeActionTypes.GetPreviousWorkOrderCompleted;

  constructor(public payload: IActivityHistory[]) {}
}

export class GetIsLinePathUtilized implements Action {
  readonly type = HomeActionTypes.GetIsLinePathUtilized;

  constructor(public readonly siteId: number) {}
}

export class GetIsLinePathUtilizedCompleted implements Action {
  readonly type = HomeActionTypes.GetIsLinePathUtilizedCompleted;

  constructor(public payload: boolean) {}
}

export class AssignWoLinePathJob implements Action {
  readonly type = HomeActionTypes.AssignWoLinePathJob;

  constructor(
    public readonly workOrderId: number,
    public readonly linePathId: number,
    public readonly homeLineId: number,
    public readonly method: LinePathAssignmentMethods,
  ) {}
}

export class AssignWoLinePathJobCompleted implements Action {
  readonly type = HomeActionTypes.AssignWoLinePathJobCompleted;

  constructor(public readonly method: LinePathAssignmentMethods, public readonly createdWorkOrders: number[]) {}
}

export class WorkOrderMissingControl implements Action {
  readonly type = HomeActionTypes.WorkOrderMissingControl;

  constructor(public readonly params: IWorkOrderControlParams) {}
}

export class WorkOrderMissingControlCompleted implements Action {
  readonly type = HomeActionTypes.WorkOrderMissingControlCompleted;

  constructor(public payload: IWorkOrderControlResponse) {}
}

export type HomeActions =
  | StartLoadHomeInformation
  | HomeInformationLoaded
  | SetHomeActivityButtons
  | GetQuantityFromSensor
  | GetActivityTreeGraph
  | ActivityTreeGraphDataLoaded
  | SaveWorkOrderFeedback
  | SaveWorkOrderFeedbackLoaded
  | ClearWorkOrderFeedbackForm
  | GetWorkOrderFeedback
  | GetWorkOrderFeedbackLoaded
  | UpdateWorkOrderFeedback
  | UpdateWorkOrderFeedbackCompleted
  | ChangeActivity
  | SetChangeActivityLoading
  | ChangeActivityCompleted
  | ChangeActivityError
  | SearchTaskByActivityId
  | SearchTaskByActivityIdCompleted
  | GetTasksByActivityId
  | GetTasksByActivityIdCompleted
  | UpdateCurrentActivity
  | UpdateCurrentActivityCompleted
  | CheckWorkOrder
  | CheckWorkOrderCompleted
  | UpdateWorkOrder
  | UpdateWorkOrderCompleted
  | GetWorkOrder
  | GetWorkOrderCompleted
  | WorkOrderStatus
  | WorkOrderStatusCompleted
  | GetVersionDetail
  | GetVersionDetailCompleted
  | GetProductPerformance
  | GetProductPerformanceCompleted
  | ProductSearch
  | ProductSearchCompleted
  | ProductSearchFinalized
  | GetWorkOrderNumbers
  | GetWorkOrderNumbersCompleted
  | SearchUsersByFilter
  | SearchUsersByFilterCompleted
  | SearchAssetsByFilter
  | SearchAssetsByFilterCompleted
  | FetchError
  | HomeMetricSetItemsSetDefaultState
  | HomeMetricSetItemsLoading
  | HomeMetricSetItemsLoaded
  | PhaseDurationsLoading
  | PhaseDurationsLoaded
  | PhaseDurationIntervalTick
  | ResetPhaseDurationOnSiteLineSelection
  | PhaseCommentsLoading
  | PhaseCommentsLoaded
  | UpdatePhaseComment
  | UpdatePhaseCommentCompleted
  | DeletePhaseComment
  | DeletePhaseCommentCompleted
  | CreatePhaseComment
  | CreatePhaseCommentCompleted
  | UpdateIsAutomatedPhaseCommentsActive
  | UpdatePhaseCommentsEnteredStatus
  | UpdatePreviousPhase
  | GetLineInfoThatWorkOrderLoading
  | GetLineInfoThatWorkOrderLoaded
  | SetRequestType
  | SplitWorkOrderOnLineLoad
  | SplitWorkOrderOnLinePredictedWorkOrderNumberLoad
  | SplitWorkOrderOnLinePredictedWorkOrderNumberLoaded
  | ProductVersionsLoad
  | ProductVersionsLoaded
  | GetJobs
  | GetJobsCompleted
  | UpdateLineInformation
  | UpdateLineInformationCompleted
  | ShowTaskTargetExceedErrorMessage
  | HideTaskTargetExceedErrorMessage
  | IsOpcIntegrationInterrupted
  | StartActivityHistoryDataLoading
  | ActivityHistoryDataLoaded
  | GetProductTransitionMatrix
  | GetProductTransitionMatrixCompleted
  | GetPreviousWorkOrder
  | GetPreviousWorkOrderCompleted
  | AssignWoLinePathJob
  | AssignWoLinePathJobCompleted
  | GetIsLinePathUtilized
  | MultipleChangeActivity
  | MultipleChangeActivityDone
  | GetIsLinePathUtilizedCompleted
  | WorkOrderMissingControl
  | WorkOrderMissingControlCompleted;
